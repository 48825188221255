import {
  CLEAR_ERRORS,
  CLEAR_INFO,
  CLEAR_LOGS,
  CLEAR_TERMINAL,
  SET_ERRORS,
  SET_INFO,
  SET_LOGS,
  GET_TERMINAL_DATA_FROM_SESSION_STORAGE,
} from '../constants/actionTypes';

export const setLogs = (data) => (dispatch) => {
  dispatch({ type: SET_LOGS, payload: data });
};

export const setErrors = (data) => (dispatch) => {
  dispatch({ type: SET_ERRORS, payload: data });
};

export const setInfo = (data) => (dispatch) => {
  dispatch({ type: SET_INFO, payload: data });
};

export const clearTerminal = () => (dispatch) => {
  dispatch({ type: CLEAR_TERMINAL });
};

export const clearLogs = () => (dispatch) => {
  dispatch({ type: CLEAR_LOGS });
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

export const clearInfo = () => (dispatch) => {
  dispatch({ type: CLEAR_INFO });
};

export const getTerminalDataFromSessionStorage = () => (dispatch) =>
  dispatch({
    type: GET_TERMINAL_DATA_FROM_SESSION_STORAGE,
  });
