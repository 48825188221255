import React from 'react';

import NavBar from '../../components/NavBar/navBar';
import Terminal from '../../components/Terminal/terminal';

const MainLayout = (props) => {
  return (
    <div className="main-layout">
      <NavBar />
      <div className="rwm-wrapper">
        <div className="rwm-content">{props.children}</div>
      </div>
      <Terminal />
    </div>
  );
};

export default MainLayout;
