import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getDevices } from './actions/devices.actions';
import { logout, getUser } from './actions/auth.actions';
import { useHistory } from 'react-router';

import Routes from './routes';

import './App.sass';

const App = ({ getDevices, logout, getUser }) => {
  const history = useHistory();
  useEffect(() => {
    if (localStorage.getItem('token')) {
      getUser().then(() =>
        getDevices().catch((res) => {
          if (res.response === undefined) history.push('/error');
          const status = res?.response?.status;
          if (status === 401) {
            logout();
            history.push('/login');
          }
          if (status === 500) history.push('/error');
        }),
      );
    }
  }, [getDevices, logout, getUser, history]);

  return (
    <div className="rwm-app">
      <Routes />
    </div>
  );
};

export default connect(null, { getDevices, logout, getUser })(App);
