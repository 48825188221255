import { ERRORS, GET_USERS, CREATE_USER, DELETE_USER, UPDATE_USER } from '../constants/actionTypes';
import api from '../utils/api';
import { notificationSuccess } from '../utils/default';

export const getUsers = () => (dispatch) => {
  return api.users
    .getUsers()
    .then((res) => {
      // notificationSuccess(null, res.data.Message.length === 0 ? 'Data loaded' : res.data.Message);
      dispatch({ type: GET_USERS, payload: res.data });
      dispatch({
        type: ERRORS,
        payload: {},
      });
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      dispatch({
        type: ERRORS,
        payload: err,
      });
      return Promise.reject(err);
    });
};

export const addUser = (newUser) => (dispatch) => {
  return api.users
    .addUser(newUser)
    .then((res) => {
      notificationSuccess(null, res.data.Message.length === 0 ? 'Data loaded' : res.data.Message);
      dispatch({ type: CREATE_USER });
      return api.users.getUsers().then((res) => {
        dispatch({ type: GET_USERS, payload: res.data });
        dispatch({
          type: ERRORS,
          payload: {},
        });
        return Promise.resolve(res.data);
      });
    })
    .catch((err) => {
      dispatch({
        type: ERRORS,
        payload: err,
      });
      return Promise.reject(err);
    });
};

export const updateUser = (user, newData) => (dispatch) => {
  return api.users
    .updateUser(user, newData)
    .then((res) => {
      notificationSuccess(null, res.data.Message.length === 0 ? 'User updated' : res.data.Message);
      dispatch({ type: UPDATE_USER });
      return api.users.getUsers().then((res) => {
        dispatch({ type: GET_USERS, payload: res.data });
        dispatch({
          type: ERRORS,
          payload: {},
        });
        return Promise.resolve(res.data);
      });
    })
    .catch((err) => {
      dispatch({
        type: ERRORS,
        payload: err,
      });
      return Promise.reject(err);
    });
};

export const deleteUser = (userId) => (dispatch) => {
  return api.users
    .deleteUser(userId)
    .then((res) => {
      notificationSuccess(null, res.data.Message.length === 0 ? 'User deleted' : res.data.Message);
      dispatch({ type: DELETE_USER });
      return api.users.getUsers().then((res) => {
        dispatch({ type: GET_USERS, payload: res.data });
        dispatch({
          type: ERRORS,
          payload: {},
        });
        return Promise.resolve(res.data);
      });
    })
    .catch((err) => {
      dispatch({
        type: ERRORS,
        payload: err,
      });
      return Promise.reject(err);
    });
};
