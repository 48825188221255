import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import AddKey from './AddKey';
import KeysTable from './KeysTable';

import { getKeys } from '../../actions/keys.actions';
import { addKey, deleteKey, updateKey } from '../../actions/clientKey.actions';
import { getUsers } from '../../actions/users.actions';

import './clientKeys.sass';

const ClientKeys = ({ getKeys, keys, addKey, updateKey, deleteKey, getUsers, users, auth }) => {
  const [visibleAdd, setVisibleAdd] = useState(false);

  useEffect(() => {
    getKeys().then(() => auth.role_id === 1 && getUsers());
  }, [getKeys, getUsers, auth]);

  const addKeyVisible = (visible) => setVisibleAdd(visible);

  const showAddKey = () => {
    addKeyVisible(true);
  };

  const createKey = ({ deviceId, status, key, user_id }) => {
    return addKey(deviceId, { DeviceID: deviceId, Key: key, Status: status, user_id });
  };

  const updateKeyAction = (deviceId, data) => {
    updateKey(deviceId, data);
  };

  const deleteKeyAction = (id) => deleteKey(id);

  return (
    <div className="client-keys">
      <Button icon={<PlusOutlined />} className="settings_dropdown_btn" onClick={showAddKey} />
      <AddKey visibleAdd={visibleAdd} addKeyVisible={addKeyVisible} addKey={createKey} users={users} auth={auth} />
      <KeysTable keys={keys} deleteKey={deleteKeyAction} updateKey={updateKeyAction} users={users} auth={auth} />
    </div>
  );
};

ClientKeys.propTypes = {
  keys: PropTypes.shape({
    Message: PropTypes.string,
    Data: PropTypes.array,
  }),
  devices: PropTypes.shape({
    Message: PropTypes.string,
    Data: PropTypes.array,
  }),
};

const mapStateToProps = (state) => ({
  keys: state.keys,
  users: state.users,
  auth: state.auth,
});

export default connect(mapStateToProps, { getKeys, addKey, updateKey, deleteKey, getUsers })(ClientKeys);
