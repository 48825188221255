import React from 'react';
import moment from 'moment';

export default (upTimeSeconds) => {
  const duration = moment.duration(upTimeSeconds, 'seconds');

  const months = Math.trunc(duration.months());
  const days = Math.trunc(duration.days());
  const hours = Math.trunc(duration.hours());
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return (
    <div>
      {months > 0 && <span>M:{months} </span>}
      {days > 0 && <span>d:{days} </span>}
      {hours > 0 && <span>h:{hours} </span>}
      {minutes > 0 && <span>m:{minutes} </span>}
      {seconds > 0 && <span>s:{seconds} </span>}
    </div>
  );
};
