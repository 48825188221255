import React, { memo, useMemo } from 'react';
import { WifiOutlined } from '@ant-design/icons';
import moment from 'moment';

import './deviceIndicators.sass';

const DeviceIndicators = ({ status, ApdWifi, LastCheck }) => {
  const now = moment(new Date());
  const registration = moment.utc(LastCheck, 'YYYY-MM-DD HH:mm:ss').local();
  const duration = moment.duration(now.diff(registration));

  const lastCheckTime = useMemo(() => {
    const months = duration.months();
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    let time = undefined;

    if (months > 0) time = <span>{months} months </span>;
    else if (days > 0) time = <span>{days} days </span>;
    else if (hours > 0) time = <span>{hours} hours </span>;
    else if (minutes > 0) time = <span>{minutes} minutes</span>;

    return (
      <div>
        <span>Last online: </span>
        {time}
      </div>
    );
  }, [duration]);

  return (
    <div className="device-indicators">
      {/*<span className="device-indicators__separator">|</span>*/}
      {status ? (
        <span className="device-indicators__on" />
      ) : (
        <>
          <span className="device-indicators__last-time">{lastCheckTime}</span>
          <span className="device-indicators__off" />
        </>
      )}

      <div className={`device-indicators__wifi${ApdWifi ? '' : ' device-indicators__wifi--off'}`}>
        <WifiOutlined />
      </div>
    </div>
  );
};

export default memo(DeviceIndicators);
