import api from '../utils/api';

import { defaultClientName, notificationSuccess } from '../utils/default';

import { CREATE_CONFIG, DELETE_CONFIG, ERRORS, GET_DEVICES, UPDATE_CONFIG } from '../constants/actionTypes';
import { getDefaultClient } from './defaultClient.actions';

export const createConfig = (id, data) => (dispatch) => {
  return api.config
    .createConfig(id, data)
    .then((res) => {
      dispatch({ type: CREATE_CONFIG });
      notificationSuccess(null, res.data.Message);
      dispatch(getDefaultClient(defaultClientName));
      api.devices.getDevices().then((res) => {
        dispatch({
          type: GET_DEVICES,
          payload: res.data,
        });
        dispatch({
          type: ERRORS,
          payload: {},
        });
      });
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      dispatch({
        type: ERRORS,
        payload: err.response.data.Data,
      });
      return Promise.reject(err);
    });
};

export const updateConfig = (id, data) => (dispatch) => {
  return api.config
    .updateConfig(id, data)
    .then((res) => {
      dispatch({ type: UPDATE_CONFIG });
      notificationSuccess(null, res.data.Message);
      dispatch(getDefaultClient(defaultClientName));
      return api.devices.getDevices().then((res) => {
        dispatch({
          type: GET_DEVICES,
          payload: res.data,
        });
        dispatch({
          type: ERRORS,
          payload: {},
        });
        return Promise.resolve(res.data);
      });
    })
    .catch((err) => {
      dispatch({
        type: ERRORS,
        payload: err.response.data.Data,
      });
      return Promise.reject(err);
    });
};

export const deleteConfig = (id) => (dispatch) => {
  api.config.deleteConfig(id).then((res) => {
    dispatch({ type: DELETE_CONFIG });
    notificationSuccess(null, res.data.Message);
    dispatch(getDefaultClient(defaultClientName));
    api.devices.getDevices().then((res) => {
      dispatch({
        type: GET_DEVICES,
        payload: res.data,
      });
    });
  });
};
