import React, { memo, useCallback, useEffect, useState, useMemo } from 'react';

import { Modal, Row, Col, Input, Button } from 'antd';

import './configModal.sass';

const ConfigModal = ({ modalConf, hideConfig, config, updateConfig, createConfig, id, errors }) => {
  const [data, setData] = useState({});
  const disabledList = ['ConfigModelID', 'Bss'];
  const excludedList = ['ID', 'CreatedAt', 'UpdatedAt', 'DeletedAt'];
  const [more, setMore] = useState(false);
  const shortList = [
    'Ssid',
    'Venue_name',
    'Domain_name',
    'Hessid',
    'Nai_realm',
    'Nas_identifier',
    'Hs20_oper_friendly_name',
  ];
  // WHOLE OBJECT
  useEffect(() => {
    setData(config);
  }, [config]);

  const highlight = useCallback(
    (obj) => {
      const item = errors?.find((item) => item.Field === obj);
      return { detected: item !== undefined, err: item };
    },
    [errors],
  );

  // INSERTING DATA TO FIELDS
  const changeHandler = useCallback(
    (e) => {
      const { name, value } = e.target;
      setData((state) => ({
        ...state,
        [name]: value,
      }));
    },
    [setData],
  );
  const applyChanges = useCallback(() => {
    updateConfig && updateConfig(data.ID, data).then(() => hideConfig());
  }, [data, updateConfig, hideConfig]);

  const create = useCallback(() => {
    createConfig && createConfig(id, data).then(() => hideConfig());
  }, [id, data, createConfig, hideConfig]);

  const changeDisplayingInfo = () => {
    setMore(!more);
  };

  const otherInfo = useCallback(() => {
    if (more) {
      return Object.keys(data);
    } else {
      return shortList.filter((item) => item !== Object.keys(data));
    }
  }, [shortList, data, more]);

  const configurationInputs = useMemo(
    () =>
      data &&
      otherInfo().map(
        (obj) =>
          !excludedList.includes(obj) && (
            <Col xs={24} md={8} key={obj}>
              <div className="content-field">
                <Input
                  className={highlight(obj).detected && 'error-input'}
                  name={obj}
                  disabled={disabledList.includes(obj)}
                  addonBefore={obj}
                  value={data[obj]}
                  onChange={changeHandler}
                />
                <span className="error-message">{highlight(obj).err?.Message}</span>
              </div>
            </Col>
          ),
      ),
    [data, changeHandler, disabledList, excludedList, highlight, otherInfo],
  );

  return (
    <Modal
      className="config-modal"
      visible={modalConf.visibility}
      onOk={modalConf.type === 'update' ? applyChanges : create}
      onCancel={hideConfig}
      okText={modalConf.type === 'update' ? 'Update' : 'Create'}
      title="Configs setup"
      okButtonProps={{ shape: 'round' }}
      cancelButtonProps={{ shape: 'round' }}
    >
      <div className="config-modal_content">
        <Row gutter={[16, 20]}>{configurationInputs}</Row>
        <Button type="text" onClick={changeDisplayingInfo}>
          {more ? 'Less' : 'More'}
        </Button>
      </div>
    </Modal>
  );
};

export default memo(ConfigModal);
