import api from '../utils/api';
import { GET_TEMPLATES, DELETE_TEMPLATE, SAVE_AS_TEMPLATE, IMPORT_TEMPLATE } from '../constants/actionTypes';
import { notificationSuccess } from '../utils/default';

export const getTemplates = () => (dispatch) => {
  return api.templates
    .getTemplates()
    .then((res) => {
      dispatch({ type: GET_TEMPLATES, payload: res.data });

      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const deleteTemplate = (id) => (dispatch) =>
  api.templates
    .deleteTemplate(id)
    .then((res) => {
      dispatch({ type: DELETE_TEMPLATE, payload: id });

      return res.data;
    })
    .catch((err) => err);

const downlaodFile = (res, id) => {
  const url = window.URL.createObjectURL(new Blob([JSON.stringify(res.data)], { type: 'application/octet-stream' }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${id}.json`); //or any other extension
  document.body.appendChild(link);
  link.click();
};

export const exportTemplate = (id) => () =>
  api.templates
    .exportTemplate(id)
    .then((res) => {
      downlaodFile(res, id);
      return res.data;
    })
    .catch((err) => err);

export const exportTemplateFromDevice = (id) => () =>
  api.templates
    .exportTemplateFromDevice(id)
    .then((res) => {
      downlaodFile(res, id);
      return res.data;
    })
    .catch((err) => err);

export const saveAsTemplate = (id, name) => (dispatch) =>
  api.templates
    .saveAsTemplate(id, name)
    .then((res) => {
      dispatch({ type: SAVE_AS_TEMPLATE, payload: res.data });
      return res.data;
    })
    .catch((err) => err);

export const importTemplate = (file) => (dispatch) => {
  const formData = new FormData();
  formData.append('file', file);
  return api.templates
    .importTemplate(formData)
    .then((res) => {
      dispatch({ type: IMPORT_TEMPLATE, payload: res.data });

      return res.data;
    })
    .catch((err) => err);
};

export const setTemplateToClient = (deviceId, templateId) => () =>
  api.templates
    .setTemplateToClient(deviceId, templateId)
    .then((res) => {
      notificationSuccess('Template applied');
      return res.data;
    })
    .catch((err) => err);
