import io from 'socket.io-client';
import store from '../store';
import {
  SET_ERRORS,
  SET_INFO,
  SET_LOGS,
  UPDATE_DEVICE_FROM_SOCKET,
  GET_SOCKET_SCAN,
  GET_CELL_INFO,
  GET_APPLIED_SOFTWARE,
} from '../constants/actionTypes';
import { notificationWarning } from './default';

export let socket;

const SocketInit = () => {
  socket = io.connect(process.env.REACT_APP_API_SOCKET, { transports: ['websocket'] });

  socket.on('logs', (logs) => {
    store.dispatch({ type: SET_LOGS, payload: logs });
  });
  socket.on('errors', (errors) => {
    store.dispatch({ type: SET_ERRORS, payload: errors });
  });
  socket.on('info', (info) => {
    store.dispatch({ type: SET_INFO, payload: info });
  });

  socket.on('DeviceUpdate', (data) => {
    store.dispatch({ type: UPDATE_DEVICE_FROM_SOCKET, payload: data });
  });

  socket.on('important', (important) => {
    const parsedMessage = JSON.parse(important);
    const roleId = parseInt(localStorage.getItem('roleId'), 10);

    if (roleId === 1 || roleId === parsedMessage?.UserRole) notificationWarning(null, parsedMessage.Info, 5);
  });

  socket.on('DeviceScan', (scan) => {
    store.dispatch({ type: GET_SOCKET_SCAN, payload: JSON.parse(scan) });
  });

  socket.on('CellInfo', (scan) => {
    const data = JSON.parse(scan);
    store.dispatch({ type: GET_CELL_INFO, payload: { id: data.DeviceID, data } });
  });

  socket.on('SoftwareUpdate', (software) => {
    const data = JSON.parse(software);
    store.dispatch({ type: GET_APPLIED_SOFTWARE, payload: { id: data.DeviceID, data } });
  });
};

export default SocketInit;
