import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, Popconfirm, Input } from 'antd';
import { PlusOutlined, LeftOutlined } from '@ant-design/icons';

import {
  getDevices,
  setPower,
  setState,
  updateDevice,
  deleteDevice,
  getScans,
  scanDevice,
} from '../../actions/devices.actions';
import { createConfig, deleteConfig, updateConfig } from '../../actions/configs.actions';
import { logout } from '../../actions/auth.actions';
import { getKey, updateKey, deleteKey, addKey } from '../../actions/clientKey.actions';
import { updateDefaultClient, getDefaultClient } from '../../actions/defaultClient.actions';
import { exportTemplateFromDevice, saveAsTemplate, getTemplates } from '../../actions/templates.actions';
import { getCellInfo, setCellInfo } from '../../actions/cellInfo.actions';
import { getAppliedSoftware } from '../../actions/software.actions';

import ConfigModal from '../../components/ConfigModal/configModal';
import ClientKeyModal from '../../components/ClientKeyModal/keyModal';
import StatusModal from '../../components/StatusModal/statusModal';
import CellInfoModal from '../../components/CellInfoModal/cellInfoModal';
import DeviceDescription from './deviceDescription';
import DeviceConfig from './deviceConfig';
import { defaultClientName } from '../../utils/default';

import { routes } from '../../constants/routes';

import './devicePage.sass';
import moment from 'moment';

const elements = ['Bss', 'Ssid', 'Auth_server_shared_secret', 'Venue_name'];
const excludedList = [
  'ID',
  'CreatedAt',
  'UpdatedAt',
  'DeletedAt',
  'DeviceID',
  'FromDevice',
  'ConfigModelID',
  'IsDefault',
  'State',
  'ApdWifi',
  'Power',
  'DeviceName',
];

export const DevicePage = ({
  getDevices,
  updateDevice,
  updateConfig,
  createConfig,
  deleteConfig,
  devices,
  defaultClient,
  errors,
  logout,
  getKey,
  updateKey,
  deleteKey,
  addKey,
  clientKey,
  updateDefaultClient,
  getDefaultClient,
  setPower,
  setState,
  auth,
  deleteDevice,
  getScans,
  scanDevice,
  exportTemplateFromDevice,
  saveAsTemplate,
  getTemplates,
  getCellInfo,
  setCellInfo,
  getAppliedSoftware,
}) => {
  const location = useLocation();
  const history = useHistory();

  const [modalConf, setModalConf] = useState({
    visibility: false,
    type: 'update',
  });
  const [selectedDevice, setSelectedDevice] = useState({});
  const [selectedConfig, setSelectedConfig] = useState({});
  const [editable, setEditable] = useState(false);
  const [visibleKeyModal, setVisibleKeyModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [cellInfoModal, setCellInfoModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(false);
  const device = selectedDevice?.CurrentConfiguration;
  const currentLocation = location.pathname.substr(1);
  let timerCheck = useRef(null);

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  useEffect(() => {
    if (currentLocation !== defaultClientName)
      getDevices().then(() => {
        getScans(currentLocation);
        getCellInfo(currentLocation);
        getAppliedSoftware(currentLocation);
      });
    else getDefaultClient(defaultClientName);
  }, [getDevices, getCellInfo, getAppliedSoftware, getScans, history, logout, getDefaultClient, currentLocation]);

  useEffect(() => {
    if (!editable) {
      if (currentLocation !== defaultClientName) {
        setSelectedDevice(devices?.Data?.find((dev) => dev.DeviceID === location.pathname.substr(1)));
      } else {
        setSelectedDevice(defaultClient);
      }
    }
  }, [devices, location.pathname, currentLocation, defaultClient, editable]);

  const checkDeviceStatus = useCallback(() => {
    const currentTime = moment()
      .subtract(1.2, 'minute')
      .format('YYYY-MM-DD HH:mm:ss');

    const lastCheckToLocal = moment
      .utc(selectedDevice.LastCheck)
      .local()
      .format('YYYY-MM-DD HH:mm:ss');

    if (moment(lastCheckToLocal).isAfter(currentTime, 'minutes')) {
      setCurrentStatus(true);
    } else setCurrentStatus(false);
  }, [selectedDevice]);

  useEffect(() => {
    clearInterval(timerCheck.current);
    if (selectedDevice && selectedDevice.LastCheck) {
      checkDeviceStatus();
      timerCheck.current = setInterval(() => checkDeviceStatus(), 1 * 1000);
    }

    return () => {
      clearInterval(timerCheck.current);
    };
  }, [selectedDevice, checkDeviceStatus]);

  useEffect(() => {
    const device = location.pathname.substr(1);
    if (device !== defaultClientName) {
      getKey(device).catch((res) => {
        if (res.response === undefined) history.push('/error');
        const status = res?.response?.status;
        if (status === 401) {
          logout();
          history.push('/login');
        }
        if (status === 500 || res.response === undefined) history.push('/error');
      });
    }
  }, [getKey, location.pathname, logout, history]);

  const highlight = useCallback(
    (obj) => {
      const item = errors?.Error?.find((item) => item.Field === obj);
      return { detected: item !== undefined, err: item };
    },
    [errors],
  );

  const showConfig = useCallback(
    (index) => {
      const config = device?.ConfigApps[index];
      setSelectedConfig(config);
      setModalConf({ visibility: true, type: 'update' });
    },
    [setSelectedConfig, setModalConf, device],
  );

  const hideConfig = useCallback(() => {
    // This set state "setSelectedConfig" is creating modalCLose glitch effect
    // setSelectedConfig({});
    setModalConf({ visibility: false, type: 'update' });
  }, [setModalConf]);

  const addNewConfig = useCallback(() => {
    const lastConfig = { ...device?.ConfigApps[device?.ConfigApps.length - 1] };
    ['ID', 'CreatedAt', 'UpdatedAt', 'DeletedAt'].map((item) => delete lastConfig[item]);
    setSelectedConfig(lastConfig);
    setModalConf({ visibility: true, type: 'create' });
  }, [device, setSelectedConfig]);

  const removeConfig = useCallback(
    (configId) => {
      deleteConfig(configId);
    },
    [deleteConfig],
  );

  const changeDeviceHandler = useCallback(
    (e) => {
      const { name, value } = e.target;
      setSelectedDevice((state) => ({
        ...state,
        [name]: name === 'Power' ? parseInt(value) : value,
      }));
    },
    [setSelectedDevice],
  );

  // UPDATE CURRENT CONFIGURATION HEADERS
  const changeCurrConf = useCallback(
    (e) => {
      const { name, value } = e.target;
      setSelectedDevice((state) => ({
        ...state,
        CurrentConfiguration: {
          ...state.CurrentConfiguration,
          Header: {
            ...state.CurrentConfiguration.Header,
            [name]: value,
          },
        },
      }));
    },
    [setSelectedDevice],
  );

  const sendUpdatedDevice = useCallback(() => {
    updateDevice(selectedDevice.DeviceID, selectedDevice).then(() => setEditable(false));
  }, [selectedDevice, updateDevice]);

  const configAppsData = useMemo(() => device?.ConfigApps, [device]);
  const configApps = useMemo(
    () =>
      configAppsData
        ? configAppsData.map((item, index) => (
            <DeviceConfig
              key={item.ID}
              item={item}
              index={index}
              elements={elements}
              showConfig={showConfig}
              isLast={configAppsData.length === 1}
              removeConfig={auth?.role_id === 1 ? removeConfig : () => null}
            />
          ))
        : [],
    [configAppsData, showConfig, removeConfig, auth],
  );

  const editObject = useCallback(() => {
    setEditable(true);
  }, [setEditable]);

  const cancelEditObject = useCallback(() => {
    setEditable(false);
  }, [setEditable]);

  const goToDevices = useCallback(() => history.push(routes.HOME), [history]);

  const showKeyModal = useCallback(() => {
    setVisibleKeyModal(!visibleKeyModal);
  }, [setVisibleKeyModal, visibleKeyModal]);

  const showStatusModal = useCallback(() => {
    setStatusModal((state) => !state);
  }, [setStatusModal]);

  const showCellInfoModal = useCallback(() => {
    setCellInfoModal((state) => !state);
  }, [setCellInfoModal]);

  const updateDefault = useCallback(() => {
    const device = { ...selectedDevice };
    ['ID', 'CreatedAt', 'UpdatedAt', 'DeletedAt'].map((item) => delete device[item]);
    updateDefaultClient(defaultClientName, { ...device, DeviceID: defaultClientName, DeviceName: 'Default' }).then(() =>
      setEditable(false),
    );
  }, [selectedDevice, updateDefaultClient]);

  const deviceEquality = useCallback(() => {
    const keys = ['ID', 'DeviceID', 'DeviceName', 'CreatedAt', 'UpdatedAt', 'DeletedAt'];
    if (selectedDevice !== undefined && Object.values(defaultClient).length && Object.values(selectedDevice).length) {
      const currentDevice = { ...selectedDevice };
      const defaultDevice = { ...defaultClient };
      keys.map((obj) => delete currentDevice[obj]);
      keys.map((obj) => delete defaultDevice[obj]);
      return JSON.stringify(currentDevice) === JSON.stringify(defaultDevice);
    }
  }, [selectedDevice, defaultClient]);

  const setDevicePower = useCallback((device, power) => setPower(device, { Power: power }), [setPower]);
  const setDeviceState = useCallback((device, power) => setState(device, { State: power }), [setState]);

  const deleteDeviceAction = useCallback(() => {
    deleteDevice(selectedDevice?.DeviceID).then(() => history.push('/'));
  }, [selectedDevice, deleteDevice, history]);

  const checkStatus = () => showStatusModal();
  const checkCellInfo = () => showCellInfoModal();

  const deviceNameOnChange = (e) => setSelectedDevice((device) => ({ ...device, DeviceName: e.target.value }));

  return (
    <div className="rwm-device">
      <div className="rwm-device_content">
        {/*CONFIG EXPANDED INFO*/}
        <ConfigModal
          modalConf={modalConf}
          hideConfig={hideConfig}
          config={selectedConfig}
          updateConfig={auth.role_id === 1 && updateConfig}
          createConfig={createConfig}
          id={selectedDevice?.DeviceID}
          errors={errors?.Error}
        />

        <StatusModal visible={statusModal} setVisible={showStatusModal} scan={scanDevice} />
        <CellInfoModal visible={cellInfoModal} setVisible={showCellInfoModal} setCellInfo={setCellInfo} />

        {/*CRUD FOR CLIENT KEY*/}
        {currentLocation !== defaultClientName && (
          <ClientKeyModal
            visible={visibleKeyModal}
            setVisible={showKeyModal}
            clientKey={clientKey}
            addKey={addKey}
            updateKey={updateKey}
            deleteKey={deleteKey}
          />
        )}

        <div className="rwm-device__navigation-wrapper">
          <div className="rwm-device__navigation_arrow" onClick={goToDevices}>
            <LeftOutlined /> <span>Back</span>
          </div>
          <div className="rwm-device__navigation_name">
            <span>Device Name:</span>
            {editable ? (
              <Input style={{ width: 200 }} value={selectedDevice?.DeviceName} onChange={deviceNameOnChange} />
            ) : (
              <span>{selectedDevice?.DeviceName}</span>
            )}
          </div>
          {currentLocation !== defaultClientName && <Button onClick={showKeyModal}>Client key</Button>}
          {currentLocation !== defaultClientName && (
            <Popconfirm title="Are you sure to delete this device ?" onConfirm={deleteDeviceAction}>
              <Button style={{ marginLeft: 10 }}>Delete Device</Button>
            </Popconfirm>
          )}
        </div>

        <div className="rwm-device__grid">
          {/*DEVICE DESCRIPTION*/}
          <DeviceDescription
            selectedDevice={selectedDevice}
            headers={selectedDevice?.CurrentConfiguration?.Header}
            editable={(auth.role_id === 1 && editable) || editable}
            editObject={editObject}
            cancelEditObject={cancelEditObject}
            excludedList={excludedList}
            changeDeviceHandler={changeDeviceHandler}
            changeCurrConf={changeCurrConf}
            updateDevice={sendUpdatedDevice}
            highlight={highlight}
            updateDefault={updateDefault}
            defaultClient={currentLocation === defaultClientName}
            equality={deviceEquality()}
            setPower={setDevicePower}
            setState={setDeviceState}
            status={currentStatus}
            checkStatus={checkStatus}
            checkCellInfo={checkCellInfo}
            auth={auth}
            isDefault={currentLocation === defaultClientName}
            exportTemplateFromDevice={exportTemplateFromDevice}
            saveAsTemplate={saveAsTemplate}
          />

          {/*APP CONFIGS LIST*/}
          {configApps}
          {configAppsData?.length < 8 && auth?.role_id === 1 && (
            <Button
              className="add-config"
              onClick={addNewConfig}
              ghost
              icon={<PlusOutlined className="add-config_icon" />}
            />
          )}
        </div>
      </div>
    </div>
  );
};

DevicePage.propTypes = {
  devices: PropTypes.shape({
    Message: PropTypes.string,
    Data: PropTypes.array,
  }),
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  clientKey: PropTypes.object,
  defaultClient: PropTypes.object,
};

const mapStateToProps = (state) => ({
  devices: state.devices,
  defaultClient: state.defaultClient,
  errors: state.errors,
  clientKey: state.clientKey,
  status: state.terminal?.status,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getDevices,
  updateDevice,
  createConfig,
  deleteConfig,
  updateConfig,
  logout,
  getKey,
  addKey,
  updateKey,
  deleteKey,
  updateDefaultClient,
  getDefaultClient,
  setPower,
  setState,
  deleteDevice,
  getScans,
  scanDevice,
  exportTemplateFromDevice,
  saveAsTemplate,
  getTemplates,
  getCellInfo,
  setCellInfo,
  getAppliedSoftware,
})(DevicePage);
