import { ERRORS, GET_KEYS } from '../constants/actionTypes';
import api from '../utils/api';

export const getKeys = () => (dispatch) => {
  return api.keys
    .getKeys()
    .then((res) => {
      // notificationSuccess(null, res.data.Message.length === 0 ? 'Data loaded' : res.data.Message);
      dispatch({ type: GET_KEYS, payload: res.data });
      dispatch({
        type: ERRORS,
        payload: {},
      });
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      dispatch({
        type: ERRORS,
        payload: err,
      });
      return Promise.reject(err);
    });
};
