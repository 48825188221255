import React from 'react';
import { Result } from 'antd';

import './notFoundPage.sass';

const NotFoundPage = () => {
  return (
    <div className="not-found">
      <Result status="404" />
      <div className="not-found_code">404</div>
      <div className="not-found_text">Sorry, the page you visited does not exist.</div>
    </div>
  );
};

export { NotFoundPage };
