import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Typography, Dropdown, Menu, Button } from 'antd';
import {
  SettingOutlined,
  SettingFilled,
  FunnelPlotOutlined,
  FunnelPlotFilled,
  PoweroffOutlined,
  UserOutlined,
  FileSyncOutlined,
  KeyOutlined,
  ControlOutlined,
  AppstoreAddOutlined,
} from '@ant-design/icons';

import { routes, routesName } from '../../constants/routes';

import NavBarItem from './navBarItem';
import { logout } from '../../actions/auth.actions';

import './navBar.sass';
import { connect } from 'react-redux';

const { Title } = Typography;

const NavBar = ({ logout, auth }) => {
  const history = useHistory();
  const goToHome = useCallback(() => history.push(routes.HOME), [history]);
  const goToSettings = useCallback(() => history.push(routes.SETTINGS), [history]);
  const goToDefault = useCallback(() => history.push(routes.DEFAULT), [history]);
  const goToKeys = useCallback(() => history.push(routes.KEYS), [history]);
  const goToTemplates = useCallback(() => history.push(routes.TEMPLATES), [history]);
  const goToSoftware = useCallback(() => history.push(routes.SOFTWARE), [history]);
  const logOut = () => {
    logout();
    history.push('/auth');
  };

  console.warn(auth);

  const menu = (
    <Menu>
      <Menu.Item>
        <Button className="log-btn" type="link" onClick={logOut} icon={<PoweroffOutlined />}>
          logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="main-header">
      <Title className="main-header_title" level={4} onClick={goToHome}>
        <span>RWMP</span>
        <span>Server</span>
      </Title>
      <div className="main-header__link-wrapper">
        <div className="main-header__link-wrapper_links">
          <NavBarItem
            goTo={goToHome}
            routePath={routes.HOME}
            routeName={routesName.HOME}
            IconActive={FunnelPlotFilled}
            IconDisabled={FunnelPlotOutlined}
          />
          {auth?.role_id === 1 && (
            <NavBarItem
              goTo={goToSettings}
              routePath={routes.SETTINGS}
              routeName={routesName.SETTINGS}
              IconActive={SettingFilled}
              IconDisabled={SettingOutlined}
            />
          )}
          <NavBarItem
            goTo={goToDefault}
            routePath={routes.DEFAULT}
            routeName={routesName.DEFAULT}
            IconActive={FileSyncOutlined}
            IconDisabled={FileSyncOutlined}
          />
          <NavBarItem
            goTo={goToKeys}
            routePath={routes.KEYS}
            routeName={routesName.KEYS}
            IconActive={KeyOutlined}
            IconDisabled={KeyOutlined}
          />
          <NavBarItem
            goTo={goToTemplates}
            routePath={routes.TEMPLATES}
            routeName={routesName.TEMPLATES}
            IconActive={ControlOutlined}
            IconDisabled={ControlOutlined}
          />
          {auth.role_id === 1 && (
            <NavBarItem
              goTo={goToSoftware}
              routePath={routes.SOFTWARE}
              routeName={routesName.SOFTWARE}
              IconActive={AppstoreAddOutlined}
              IconDisabled={AppstoreAddOutlined}
            />
          )}
        </div>
        <div className="main-header__link-wrapper_side-actions">
          <Dropdown overlay={menu} placement="bottomRight">
            <Button shape="circle">
              <UserOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(NavBar);
