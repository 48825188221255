import { GET_KEY, LOGOUT } from '../../constants/actionTypes';

const initialState = {};

export default function clientKey(state = initialState, action = {}) {
  switch (action.type) {
    case GET_KEY:
      return {
        ...action.payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
