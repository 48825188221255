import { ERRORS, LOGOUT } from '../../constants/actionTypes';

const initialState = {};

export default function errors(state = initialState, action = {}) {
  switch (action.type) {
    case ERRORS:
      return {
        ...action.payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
