import api from '../utils/api';

import { notificationError, notificationSuccess } from '../utils/default';

import {
  ERRORS,
  GET_DEVICES,
  UPDATE_DEVICE,
  SET_POWER_DEVICE,
  SET_STATE_DEVICE,
  DELETE_DEVICE,
  GET_SCANS,
  SCAN_DEVICE,
} from '../constants/actionTypes';

export const getDevices = () => (dispatch) => {
  return api.devices
    .getDevices()
    .then((res) => {
      // notificationSuccess(null, res.data.Message);
      dispatch({
        type: GET_DEVICES,
        payload: res.data,
      });
      dispatch({
        type: ERRORS,
        payload: {},
      });
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      const status = err?.response?.status;
      status !== 401 && notificationError(null, err?.message);
      dispatch({
        type: ERRORS,
        payload: true,
      });
      return Promise.reject(err);
    });
};

export const updateDevice = (device, data) => (dispatch) => {
  return api.devices
    .updateDevice(device, data)
    .then(() => {
      dispatch({ type: UPDATE_DEVICE });
      notificationSuccess(null, 'Data updated');
      return api.devices.getDevices().then((res) => {
        dispatch({
          type: GET_DEVICES,
          payload: res.data,
        });
        dispatch({
          type: ERRORS,
          payload: {},
        });
        return Promise.resolve(res.data);
      });
    })
    .catch((err) => {
      dispatch({
        type: ERRORS,
        payload: err.response.data.Data,
      });
      return Promise.reject(err);
    });
};

export const setPower = (device, power) => (dispatch) => {
  return api.devices
    .setPower(device, power)
    .then(() => {
      dispatch({ type: SET_POWER_DEVICE });
      notificationSuccess(null, 'Power changed!');
      return api.devices.getDevices().then((res) => {
        dispatch({
          type: GET_DEVICES,
          payload: res.data,
        });
        dispatch({
          type: ERRORS,
          payload: {},
        });
        return Promise.resolve(res.data);
      });
    })
    .catch((err) => {
      dispatch({
        type: ERRORS,
        payload: err.response.data.Data,
      });
      return Promise.reject(err);
    });
};

export const setState = (device, state) => (dispatch) => {
  return api.devices
    .setState(device, state)
    .then(() => {
      dispatch({ type: SET_STATE_DEVICE });
      notificationSuccess(null, 'State changed!');
      return api.devices.getDevices().then((res) => {
        dispatch({
          type: GET_DEVICES,
          payload: res.data,
        });
        dispatch({
          type: ERRORS,
          payload: {},
        });
        return Promise.resolve(res.data);
      });
    })
    .catch((err) => {
      dispatch({
        type: ERRORS,
        payload: err.response.data.Data,
      });
      return Promise.reject(err);
    });
};

export const deleteDevice = (id) => (dispatch) =>
  api.devices
    .deleteDevice(id)
    .then((res) => {
      dispatch({
        type: DELETE_DEVICE,
        payload: id,
      });
      return res.data;
    })
    .catch((err) => err);

export const getScans = (deviceId) => (dispatch) =>
  api.devices
    .getScans(deviceId)
    .then((res) => {
      dispatch({
        type: GET_SCANS,
        payload: { id: deviceId, data: res.data.Data },
      });
      return res.data;
    })
    .catch((err) => err);

export const scanDevice = (deviceId, advance) => (dispatch) =>
  api.devices
    .scan(deviceId, advance)
    .then((res) => {
      dispatch({
        type: SCAN_DEVICE,
        payload: deviceId,
      });
      return res.data;
    })
    .catch((err) => err);
