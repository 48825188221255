import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import ImportTemplate from './ImportTemplate';
import TemplatesTable from './TemplatesTable';

import {
  getTemplates,
  deleteTemplate,
  exportTemplate,
  importTemplate,
  setTemplateToClient,
} from '../../actions/templates.actions';
import { getUsers } from '../../actions/users.actions';

import './templatesPage.sass';

const TemplatePage = ({
  getTemplates,
  templates,
  deleteTemplate,
  exportTemplate,
  getUsers,
  users,
  auth,
  importTemplate,
  devices,
  setTemplateToClient,
}) => {
  const [modalVisibility, setModalVisibility] = useState(false);

  useEffect(() => {
    getTemplates().then(() => auth.role_id === 1 && getUsers());
  }, [getTemplates, getUsers, auth]);

  const setVisibility = (visible) => setModalVisibility(visible);

  const showAddKey = () => setVisibility(true);

  const importTemplateAction = (file) => importTemplate(file);

  return (
    <div className="client-keys">
      <Button icon={<PlusOutlined />} className="settings_dropdown_btn" onClick={showAddKey} />
      <ImportTemplate
        modalVisibility={modalVisibility}
        setVisibility={setVisibility}
        importTemplate={importTemplateAction}
      />
      <TemplatesTable
        templates={templates}
        deleteTemplate={deleteTemplate}
        exportTemplate={exportTemplate}
        users={users}
        devices={devices}
        setTemplateToClient={setTemplateToClient}
      />
    </div>
  );
};

TemplatePage.propTypes = {
  devices: PropTypes.array,
};

const mapStateToProps = (state) => ({
  templates: state.templates,
  users: state.users,
  auth: state.auth,
  devices: state.devices?.Data,
});

export default connect(mapStateToProps, {
  getTemplates,
  deleteTemplate,
  getUsers,
  exportTemplate,
  importTemplate,
  setTemplateToClient,
})(TemplatePage);
