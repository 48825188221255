import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import AddUser from './AddUser';
import UsersTable from './UsersTable';
import { addUser, getUsers, deleteUser, updateUser } from '../../actions/users.actions';

import './settings.sass';

const Settings = ({ getUsers, users, addUser, updateUser, deleteUser }) => {
  const [visibleAdd, setVisibleAdd] = useState(false);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const addUserVisible = (visible) => setVisibleAdd(visible);
  const showAddUser = () => {
    addUserVisible(true);
  };

  return (
    <div className="settings">
      <Button icon={<PlusOutlined />} className="settings_dropdown_btn" onClick={showAddUser} />
      <AddUser visibleAdd={visibleAdd} addUserVisible={addUserVisible} addUser={addUser} />
      <UsersTable users={users} deleteUser={deleteUser} updateUser={updateUser} />
    </div>
  );
};

Settings.propTypes = {
  users: PropTypes.shape({
    Message: PropTypes.string,
    Data: PropTypes.array,
  }),
};

const mapStateToProps = (state) => ({
  users: state.users,
});

export default connect(mapStateToProps, { getUsers, addUser, deleteUser, updateUser })(Settings);
