import { Card, Input, Select, Modal } from 'antd';

import React, { useCallback, useState } from 'react';

import './addKey.sass';
import { notificationWarning } from '../../utils/default';

const { Option } = Select;

const AddKey = ({ addKeyVisible, addKey, visibleAdd, users, auth }) => {
  const [newKey, setNewKey] = useState({
    key: undefined,
    status: undefined,
    deviceId: undefined,
    user_id: undefined,
  });

  const isAdmin = useCallback(() => auth?.role_id === 1, [auth]);

  const changeHandler = useCallback(
    (type) => (val) => {
      switch (type) {
        case 'input_key':
          setNewKey({
            ...newKey,
            key: val.target.value,
          });
          break;
        case 'input_device':
          setNewKey({
            ...newKey,
            deviceId: val.target.value,
          });
          break;
        case 'select_status':
          setNewKey({
            ...newKey,
            status: val,
          });
          break;
        case 'select_user':
          setNewKey({
            ...newKey,
            user_id: val,
          });
          break;
        default:
          break;
      }
    },
    [setNewKey, newKey],
  );

  const defaultSet = useCallback(
    () =>
      setNewKey({
        key: undefined,
        status: undefined,
        deviceId: undefined,
      }),
    [setNewKey],
  );

  const cancelAdd = useCallback(() => {
    addKeyVisible(false);
    defaultSet();
  }, [addKeyVisible, defaultSet]);

  const addNewKey = useCallback(() => {
    const { status, deviceId, user_id } = newKey;
    const keyData = isAdmin() ? { ...newKey } : { ...newKey, user_id: auth?.ID };

    if ((newKey, status, deviceId, user_id)) {
      addKey(keyData)
        .then(() => addKeyVisible(false))
        .catch(() => addKeyVisible(true));

      defaultSet();
    } else notificationWarning('Mandatory fields', 'All fields are required for submition');
  }, [addKey, auth, isAdmin, newKey, addKeyVisible, defaultSet]);

  return (
    <Modal onCancel={cancelAdd} onOk={addNewKey} visible={visibleAdd} centered={true}>
      <Card bordered={false} className="add-card" title="ADD KEY">
        <div className="add-card_section">
          <Input.TextArea name="input_key" onChange={changeHandler('input_key')} placeholder="Key" value={newKey.key} />
        </div>
        <div className="add-card_section">
          <Input
            name="input_device"
            onChange={changeHandler('input_device')}
            placeholder="DeviceID"
            value={newKey.deviceId}
          />
        </div>
        <div className="add-card_section">
          <Select
            name="status"
            onChange={changeHandler('select_status')}
            className="add-card_section_select"
            placeholder="Status"
            value={newKey.status}
          >
            <Option value={1}>Online</Option>
            <Option value={2}>Offline</Option>
          </Select>
        </div>
        {isAdmin() && (
          <div className="add-card_section">
            <Select
              name="user"
              onChange={changeHandler('select_user')}
              className="add-card_section_select"
              placeholder="User"
              value={newKey.user_id}
            >
              {users?.Data?.map((user) => (
                <Option key={user.ID} value={user.ID}>
                  {user.username}
                </Option>
              ))}
            </Select>
          </div>
        )}
      </Card>
    </Modal>
  );
};

export default AddKey;
