import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import ImportTemplate from './ImportSoftware';
import SoftwareTable from './SoftwareTable';

import { exportTemplate } from '../../actions/templates.actions';

import { getSoftware, deleteSoftware, importSoftware, setSoftware } from '../../actions/software.actions';
import { getUsers } from '../../actions/users.actions';

import './softwarePage.sass';

const SoftwarePage = ({
  getSoftware,
  software,
  deleteSoftware,
  setSoftware,
  getUsers,
  users,
  auth,
  importSoftware,
  devices,
}) => {
  const [modalVisibility, setModalVisibility] = useState(false);

  useEffect(() => {
    getSoftware().then(() => auth.role_id === 1 && getUsers());
  }, [getSoftware, getUsers, auth]);

  const setVisibility = (visible) => setModalVisibility(visible);

  const showAddKey = () => setVisibility(true);

  const importSoftwareAction = (file) => importSoftware(file);

  return (
    <div className="client-keys">
      <Button icon={<PlusOutlined />} className="settings_dropdown_btn" onClick={showAddKey} />
      <ImportTemplate
        modalVisibility={modalVisibility}
        setVisibility={setVisibility}
        importSoftware={importSoftwareAction}
      />
      <SoftwareTable
        software={software}
        deleteSoftware={deleteSoftware}
        exportTemplate={exportTemplate}
        users={users}
        devices={devices}
        setSoftwareToClient={setSoftware}
      />
    </div>
  );
};

SoftwarePage.propTypes = {
  devices: PropTypes.array,
};

const mapStateToProps = (state) => ({
  // templates: state.templates,
  software: state.software,
  users: state.users,
  auth: state.auth,
  devices: state.devices?.Data,
});

export default connect(mapStateToProps, {
  getSoftware,
  deleteSoftware,
  setSoftware,
  getUsers,
  importSoftware,
})(SoftwarePage);
