import React, { useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { Typography, Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { login } from '../../actions/auth.actions';

import './login.sass';

const { Title } = Typography;

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [userData, setUserData] = useState({
    username: '',
    password: '',
  });

  const changeHandler = useCallback(
    (e) => {
      const { name, value } = e.target;
      setUserData((state) => ({
        ...state,
        [name]: value,
      }));
    },
    [setUserData],
  );

  const logIn = useCallback(() => dispatch(login(userData)).then(() => history.push('/')), [
    userData,
    history,
    dispatch,
  ]);

  return (
    <div className="login">
      <div className="login_content">
        <Title className="login_content_title" level={4}>
          Welcome to RWMPServer
        </Title>
        <Form
          size="small"
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your Username!',
              },
            ]}
          >
            <Input
              className="login-form_field"
              size="large"
              name="username"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
              value={userData.username}
              onChange={changeHandler}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input
              className="login-form_field"
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              name="password"
              placeholder="Password"
              value={userData.password}
              onChange={changeHandler}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form_btn" onClick={logIn}>
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
