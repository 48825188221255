import React, { useCallback, useEffect, useState } from 'react';
import * as moment from 'moment';

import { Button, Table, Popconfirm, Space, Form } from 'antd';
import { EditFilled, DeleteFilled } from '@ant-design/icons';

import EditableCell from './EditableCell';

const KeysTable = ({ keys, updateKey, deleteKey, users, auth }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(keys?.Data);
  const [editableKey, setEditableKey] = useState({});

  useEffect(() => {
    if (keys?.Data) setData(keys.Data);
  }, [keys]);

  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.key === editingKey;

  const changeHandler = useCallback(
    (record, type) => (e) => {
      switch (type) {
        case 'Key':
          const { value } = e.target;
          setEditableKey({
            ...record,
            ...editableKey,
            Key: value,
          });
          break;
        case 'Status':
          setEditableKey({
            ...record,
            ...editableKey,
            Status: e,
          });
          break;
        case 'user_id':
          setEditableKey({
            ...record,
            ...editableKey,
            user_id: e,
          });
          break;
        default:
          break;
      }
    },
    [setEditableKey, editableKey],
  );
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const removeKey = (record) => {
    deleteKey(record.DeviceID);
  };

  const save = (record) => {
    updateKey(record.DeviceID, {
      DeviceID: record.DeviceID,
      Key: editableKey.Key,
      Status: editableKey.Status,
      user_id: editableKey.user_id,
    });
    setEditingKey('');
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'ID',
      editable: false,
      width: 60,
    },
    {
      title: 'Device ID',
      dataIndex: 'DeviceID',
      editable: false,
      width: 250,
    },
    {
      title: 'Key',
      dataIndex: 'Key',
      width: 700,
      editable: true,
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      editable: true,
      width: 110,
      render: (status) => <div>{status === 1 ? 'Online' : 'Offline'}</div>,
    },
    {
      title: 'User',
      dataIndex: 'user_id',
      editable: true,
      width: 170,
      render: (id) => {
        const index = users?.Data?.findIndex((user) => user.ID === id);
        if (index && index !== -1) {
          return <div>{users?.Data[index]?.username}</div>;
        }
      },
    },
    {
      title: 'Last update',
      dataIndex: 'UpdatedAt',
      editable: false,
      render: (time) => <div>{moment(time).format('DD-MM-YYYY HH:mm')}</div>,
      width: 200,
    },
    {
      title: 'Operations',
      dataIndex: 'operations',
      width: 120,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space size="small">
            <Button type="text" onClick={() => save(record)}>
              Save
            </Button>
            <Button onClick={cancel} type="text">
              Cancel
            </Button>
          </Space>
        ) : (
          <Space size="small">
            <Button type="text" disabled={editingKey !== ''} onClick={() => edit(record)} icon={<EditFilled />} />
            <Popconfirm title={'Sure delete key ID: ' + record.ID + '?'} onConfirm={() => removeKey(record)}>
              <Button type="text" disabled={editingKey !== ''} icon={<DeleteFilled />} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = [];
  columns.forEach((col) => {
    if (!col.editable) {
      mergedColumns.push(col);
    } else {
      mergedColumns.push({
        ...col,
        onCell: (record) => ({
          record,
          onChange: changeHandler(record, col.dataIndex),
          inputType: col.dataIndex === 'Status' ? 'select' : 'input',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      });
    }
  });

  if (auth.role_id !== 1) mergedColumns.splice(4, 1);

  return (
    <div className="users-table">
      <Form className="users-table_form" form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          className="users-table_form_table"
          size="small"
          dataSource={data}
          columns={mergedColumns}
          pagination={{
            onChange: cancel,
          }}
          rowKey={'ID'}
        />
      </Form>
    </div>
  );
};

export default KeysTable;
