import React, { memo, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import { Modal, Card, Dropdown, Button, Menu } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

import './statusModal.sass';

const StatusModal = ({ visible, setVisible, scan }) => {
  const hideModal = () => setVisible(false);
  const location = useLocation();
  const currentDeviceId = location.pathname.substr(1);
  const scans = useSelector((store) => store.devices?.scans);

  const statusData = useMemo(() => {
    if (!scans) return null;

    return scans[currentDeviceId];
  }, [scans, currentDeviceId]);

  const handleScanningClick = useCallback((e) => scan(currentDeviceId, e.key === '2').then(() => setVisible(false)), [
    currentDeviceId,
    scan,
    setVisible,
  ]);

  const menu = useMemo(
    () => (
      <Menu onClick={handleScanningClick}>
        <Menu.Item key="1" icon={<UserOutlined />}>
          Standard
        </Menu.Item>
        <Menu.Item key="2" icon={<UserOutlined />}>
          Advanced
        </Menu.Item>
      </Menu>
    ),
    [handleScanningClick],
  );

  return (
    <Modal
      className="status-modal"
      visible={visible}
      onOk={hideModal}
      centered={true}
      onCancel={hideModal}
      okText="Close"
    >
      <Card className="status-modal_card" bordered={false} title="Wi-Fi Scanning">
        <h4 className="section_title">Scanning Action: </h4>
        <Dropdown overlay={menu} trigger="click">
          <Button>
            Select scanning option <DownOutlined />
          </Button>
        </Dropdown>
        <div>
          <h4 className="section_title">Status: </h4>
          <span>{statusData?.Status?.toString()}</span>
        </div>
        <div>
          <h4 className="section_title">Advanced: </h4>
          <span>{statusData?.Advance?.toString()}</span>
        </div>
        <h4 className="section_title">Output: </h4>
        <pre>{statusData?.Output}</pre>
      </Card>
    </Modal>
  );
};

export default memo(StatusModal);
