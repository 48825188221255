import React from 'react';
import { Result } from 'antd';

import './errorPage.sass';

const ErrorPage = () => {
  return (
    <div className="error-page">
      <Result status="warning" title="There are some problems with your connection to device." />
    </div>
  );
};

export { ErrorPage };
