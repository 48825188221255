import {
  DELETE_DEVICE,
  GET_DEVICES,
  LOGOUT,
  UPDATE_DEVICE,
  UPDATE_DEVICE_FROM_SOCKET,
  GET_SCANS,
  SCAN_DEVICE,
  GET_SOCKET_SCAN,
  GET_CELL_INFO,
  SET_CELL_INFO,
  GET_APPLIED_SOFTWARE,
} from '../../constants/actionTypes';

const initialState = {
  scans: null,
};

export default function devices(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DEVICES:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_DEVICE:
      const updatedIndex = state.Data.findIndex((device) => device.ID === action.payload?.Data?.ID);
      const newDeviceArray = state.Data;
      newDeviceArray[updatedIndex] = action.payload?.Data;
      return {
        ...state,
        Data: newDeviceArray,
      };
    case UPDATE_DEVICE_FROM_SOCKET: {
      const parsedPayload = JSON.parse(action.payload);
      const index = state.Data ? state.Data.findIndex((device) => device.DeviceID === parsedPayload.DeviceID) : -1;
      const newData = state.Data ? [...state.Data] : [];

      if (index > -1) newData[index] = { ...newData[index], ...parsedPayload };

      return {
        ...state,
        Data: newData,
      };
    }
    case DELETE_DEVICE: {
      const newDeviceArray = state.Data;
      const index = state.Data.findIndex((device) => device.ID === action.payload);
      newDeviceArray.slice(index, 1);

      return {
        ...state,
        Data: newDeviceArray,
      };
    }
    case GET_SCANS: {
      return {
        ...state,
        scans: {
          ...state.scans,
          [action.payload.id]: action.payload.data,
        },
      };
    }
    case SCAN_DEVICE: {
      const newScans = { ...state.scans };
      newScans[action.payload].Status = false;

      return {
        ...state,
        scans: newScans,
      };
    }
    case GET_SOCKET_SCAN: {
      const newScans = { ...state.scans };
      newScans[action.payload.DeviceID] = action.payload;

      return {
        ...state,
        scans: newScans,
      };
    }
    case GET_CELL_INFO:
      return {
        ...state,
        cellsInfo: {
          ...state.cellsInfo,
          [action.payload.id]: action.payload.data,
        },
      };
    case SET_CELL_INFO: {
      const newCellsInfo = { ...state.cellsInfo };
      newCellsInfo[action.payload].Status = false;

      return {
        ...state,
        cellsInfo: newCellsInfo,
      };
    }
    case GET_APPLIED_SOFTWARE:
      return {
        ...state,
        software: {
          ...state.software,
          [action.payload.id]: action.payload.data,
        },
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
