import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const AuthRoute = ({ component: Component, layout: Layout, authenticated, ...rest }) => {
  return (
    <Route
      render={(props) =>
        !!localStorage.getItem('user') ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to="/auth" />
        )
      }
      {...rest}
    />
  );
};

export default AuthRoute;
