import api from '../utils/api';

import { notificationError, notificationSuccess } from '../utils/default';

import { UPDATE_DEFAULT_CLIENT, GET_DEFAULT_CLIENT, ERRORS } from '../constants/actionTypes';

export const getDefaultClient = (device) => (dispatch) => {
  return api.devices
    .viewDevice(device)
    .then((res) => {
      dispatch({
        type: GET_DEFAULT_CLIENT,
        payload: res.data.Data,
      });
      dispatch({
        type: ERRORS,
        payload: {},
      });
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      dispatch({
        type: ERRORS,
        payload: err.response?.data,
      });
      return Promise.reject(err);
    });
};
export const updateDefaultClient = (device, data) => (dispatch) => {
  return api.devices
    .updateDefault(device, data)
    .then((res) => {
      notificationSuccess(null, 'Default client saved!');
      dispatch({
        type: UPDATE_DEFAULT_CLIENT,
        payload: data,
      });
      dispatch({
        type: ERRORS,
        payload: {},
      });
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      notificationError(err.response.message, 'Default client not saved!');
      dispatch({
        type: ERRORS,
        payload: err.response.data,
      });
      return Promise.reject(err);
    });
};
