import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Select } from 'antd';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  onChange,
  children,
  users,
  dispatch = null,
  ...restProps
}) => {
  const { Option } = Select;
  let inputNode;
  switch (title) {
    case 'Status':
      inputNode = (
        <Select defaultValue={record.Status} name={dataIndex} onChange={onChange}>
          <Option value={1}>Online</Option>
          <Option value={2}>Offline</Option>
        </Select>
      );
      break;
    case 'Key':
      inputNode = <Input.TextArea rows={3} name={dataIndex} onChange={onChange} />;
      break;
    case 'User':
      inputNode = (
        <Select defaultValue={record.user_id} name={dataIndex} onChange={onChange}>
          {users?.Data?.map((user) => (
            <Option key={user.ID} value={user.ID}>
              {user.username}
            </Option>
          ))}
        </Select>
      );
      break;
    default:
      inputNode = <Input name={dataIndex} onChange={onChange} type={dataIndex === 'password' ? 'password' : 'text'} />;
      break;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const mapStateToProps = (state) => ({
  users: state.users,
});

export default connect(mapStateToProps, null)(EditableCell);
