import api from '../utils/api';
import {
  GET_SOFTWARE,
  DELETE_SOFTWARE,
  IMPORT_SOFTWARE,
  SET_SOFTWARE,
  GET_APPLIED_SOFTWARE,
} from '../constants/actionTypes';
import { notificationSuccess } from '../utils/default';

export const getSoftware = () => (dispatch) => {
  return api.software
    .get()
    .then((res) => {
      dispatch({ type: GET_SOFTWARE, payload: res.data });

      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const deleteSoftware = (id) => (dispatch) =>
  api.software
    .delete(id)
    .then((res) => {
      dispatch({ type: DELETE_SOFTWARE, payload: id });

      return res.data;
    })
    .catch((err) => err);

export const importSoftware = (file) => (dispatch) => {
  const formData = new FormData();
  formData.append('file', file);
  return new Promise((resolve, reject) =>
    api.software
      .import(formData)
      .then((res) => {
        dispatch({ type: IMPORT_SOFTWARE, payload: res.data });

        return resolve(res.data);
      })
      .catch((err) => reject(err)),
  );
};

export const setSoftware = (deviceId, softwareId) => (dispatch) =>
  api.software
    .set(deviceId, softwareId)
    .then((res) => {
      dispatch({ type: SET_SOFTWARE, payload: res.data });
      notificationSuccess('Software applied');

      return res.data;
    })
    .catch((err) => err);

export const getAppliedSoftware = (id) => (dispatch) =>
  api.software
    .getApplied(id)
    .then((res) => {
      dispatch({ type: GET_APPLIED_SOFTWARE, payload: { id, data: res.data.Data } });

      return res.data;
    })
    .catch((err) => err);
