import React, { memo, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import { Modal, Card, Button, Table } from 'antd';

import './cellStatusModal.sass';

const CellInfoModal = ({ visible, setVisible, setCellInfo }) => {
  const hideModal = () => setVisible(false);
  const location = useLocation();
  const currentDeviceId = location.pathname.substr(1);
  const cellsInfo = useSelector((store) => store.devices?.cellsInfo);

  const cellInfoData = useMemo(() => {
    if (!cellsInfo) return null;

    return cellsInfo[currentDeviceId];
  }, [cellsInfo, currentDeviceId]);

  const handleSetCellInfo = useCallback(() => setCellInfo(currentDeviceId).then(() => setVisible(false)), [
    currentDeviceId,
    setCellInfo,
    setVisible,
  ]);

  const columns = [
    {
      title: 'CellPositionID',
      dataIndex: 'CellPositionID',
      key: 'CellPositionID',
    },
    {
      title: 'Accuracy',
      dataIndex: 'Accuracy',
      key: 'Accuracy',
    },
    {
      title: 'Lat',
      dataIndex: 'Lat',
      key: 'Lat',
      render: (_, render) => <div>{render.Location.Lat}</div>,
    },
    {
      title: 'Lng',
      dataIndex: 'Lng',
      key: 'Lng',
      render: (_, render) => <div>{render.Location.Lng}</div>,
    },
  ];

  const cellPositions = useMemo(() => {
    if (!cellInfoData?.CellPosition?.Object?.length) return [];

    return cellInfoData?.CellPosition?.Object;
  }, [cellInfoData]);

  return (
    <Modal
      className="status-modal"
      visible={visible}
      onOk={hideModal}
      centered={true}
      onCancel={hideModal}
      okText="Close"
    >
      <Card className="status-modal_card" bordered={false} title="Cell">
        <Button style={{ marginBottom: 20 }} onClick={handleSetCellInfo}>
          Refresh
        </Button>
        <h4 className="section_title">Info</h4>
        <div>
          {cellInfoData?.CellInfo && (
            <div>
              <span style={{ marginRight: 10 }}>
                <span style={{ fontWeight: 700 }}>cellid: </span>
                <span>{cellInfoData?.CellInfo?.cellid}</span>
              </span>
              <span style={{ marginRight: 10 }}>
                <span style={{ fontWeight: 700 }}>imsi: </span>
                <span>{cellInfoData?.CellInfo?.imsi}</span>
              </span>
              <span style={{ marginRight: 10 }}>
                <span style={{ fontWeight: 700 }}>lac: </span>
                <span>{cellInfoData?.CellInfo?.lac}</span>
              </span>
              <span style={{ marginRight: 10 }}>
                <span style={{ fontWeight: 700 }}>mcc: </span>
                <span>{cellInfoData?.CellInfo?.mcc}</span>
              </span>
              <span style={{ marginRight: 10 }}>
                <span style={{ fontWeight: 700 }}>mnc: </span>
                <span>{cellInfoData?.CellInfo?.mnc}</span>
              </span>
              <span style={{ marginRight: 10 }}>
                <span style={{ fontWeight: 700 }}>tech: </span>
                <span>{cellInfoData?.CellInfo?.tech}</span>
              </span>
            </div>
          )}
        </div>
        <h4 className="section_title">Positions:</h4>
        {cellInfoData?.CellPosition?.Object?.length && (
          <Table columns={columns} dataSource={cellPositions} rowKey="ID" />
        )}
      </Card>
    </Modal>
  );
};

export default memo(CellInfoModal);
