import { GET_TEMPLATES, DELETE_TEMPLATE, SAVE_AS_TEMPLATE, IMPORT_TEMPLATE } from '../../constants/actionTypes';

const initialState = {};

export default function templates(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TEMPLATES:
      return {
        ...action.payload,
      };
    case DELETE_TEMPLATE: {
      const newTemplates = [...state.Data];
      const index = newTemplates.findIndex((template) => template.ID === action.payload);
      newTemplates.splice(index, 1);

      return {
        ...state,
        Data: newTemplates,
      };
    }
    case SAVE_AS_TEMPLATE: {
      const newTemplates = [...state.Data];
      if (action.payload.Data) newTemplates.push(action.payload.Data);

      return {
        ...state,
        Data: newTemplates,
      };
    }
    case IMPORT_TEMPLATE: {
      const newTemplates = [...state.Data];
      if (action.payload.Data) newTemplates.push(action.payload.Data);

      return {
        ...state,
        Data: newTemplates,
      };
    }
    default:
      return state;
  }
}
