import React from 'react';
import { Form, Input, Select } from 'antd';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, onChange, children, ...restProps }) => {
  const { Option } = Select;
  const inputNode =
    inputType === 'select' ? (
      <Select name={dataIndex} onChange={onChange}>
        <Option value="1">online</Option>
        <Option value="2">offline</Option>
      </Select>
    ) : (
      <Input name={dataIndex} onChange={onChange} type={dataIndex === 'password' ? 'password' : 'text'} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
export default EditableCell;
