import { Card, Input, Select, Modal } from 'antd';

import React, { useCallback, useState } from 'react';

import './addUser.sass';
import { notificationWarning } from '../../utils/default';

const AddUser = ({ addUserVisible, addUser, visibleAdd }) => {
  const { Option } = Select;
  const [newUser, setNewUser] = useState({
    username: '',
    password: '',
    role_id: undefined,
    status: '1',
  });
  const [confirmPassword, setConfirmPassword] = useState('');

  const changeHandler = useCallback(
    (type) => (val) => {
      if (type === 'input') {
        const { name, value } = val.target;
        setNewUser((state) => ({
          ...state,
          [name]: value,
        }));
      } else {
        setNewUser((state) => ({
          ...state,
          role_id: val,
        }));
      }
    },
    [setNewUser],
  );
  const defaultSet = () =>
    setNewUser({
      username: '',
      password: '',
      role_id: undefined,
      status: '1',
    });

  const cancelAdd = () => {
    addUserVisible(false);
    defaultSet();
  };

  const confirmPasswordOnChange = (e) => setConfirmPassword(e.target.value);

  const addNewUser = () => {
    if (newUser.password === confirmPassword) {
      addUser(newUser)
        .then(() => addUserVisible(false))
        .catch(() => addUserVisible(true));
      defaultSet();
    } else notificationWarning('Password Error', 'The passwords dont match!');
  };

  return (
    <Modal onCancel={cancelAdd} onOk={addNewUser} visible={visibleAdd} centered={true}>
      <Card bordered={false} className="add-card" title="ADD USER">
        <div className="add-card_section">
          <Input name="username" onChange={changeHandler('input')} placeholder="username" value={newUser.username} />
        </div>
        <div className="add-card_section">
          <Input
            type="password"
            name="password"
            onChange={changeHandler('input')}
            placeholder="password"
            value={newUser.password}
          />
        </div>
        <div className="add-card_section">
          <Input
            type="password"
            name="confirmPassword"
            onChange={confirmPasswordOnChange}
            placeholder="confirm password"
            value={confirmPassword}
          />
        </div>
        <div className="add-card_section">
          <Select
            value={newUser.role_id}
            name="role"
            onChange={changeHandler('select')}
            className="add-card_section_select"
            placeholder="role"
          >
            <Option value={1}>SA</Option>
            <Option value={2}>User</Option>
          </Select>
        </div>
      </Card>
    </Modal>
  );
};

export default AddUser;
