import { GLOBAL_LOADING, LOGOUT } from '../../constants/actionTypes';

const initialState = {};

export default function global(state = initialState, action = {}) {
  switch (action.type) {
    case GLOBAL_LOADING:
      return {
        ...state,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
