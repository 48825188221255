import React, { memo } from 'react';
import PropTypes, { oneOfType, string } from 'prop-types';

import { Button, Card, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const AppConfig = ({ item, elements, index, showConfig, removeConfig, isLast }) => (
  <Card className="config-card">
    <div className="config-card_content">
      {elements.map((name, i) => {
        return (
          (index !== 0 || name !== 'Bss') && (
            <div className="config-card_content_item" key={i}>
              <span className="config-card_content_item_name">{name}</span>
              <span className="config-card_content_item_desc">{item[name]}</span>
            </div>
          )
        );
      })}
    </div>
    <div>
      <div className="config-card_actions">
        <Button shape="round" type="primary" ghost onClick={() => showConfig(index)}>
          More
        </Button>
        <Popconfirm
          className="config-card_actions_pop"
          title="Are you sure delete this config ?"
          onConfirm={() => removeConfig(item.ID)}
          okText="Yes"
          cancelText="No"
        >
          {!isLast && <Button shape="circle" icon={<DeleteOutlined />} />}
        </Popconfirm>
      </div>
    </div>
  </Card>
);

AppConfig.propTypes = {
  item: PropTypes.shape({ name: string, ID: oneOfType([PropTypes.string, PropTypes.number]) }).isRequired,
  elements: PropTypes.arrayOf(string).isRequired,
  index: PropTypes.number.isRequired,
  showConfig: PropTypes.func.isRequired,
  removeConfig: PropTypes.func.isRequired,
};

export default memo(AppConfig);
