import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import { Tabs, Button, Badge } from 'antd';

import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

import {
  clearTerminal,
  clearErrors,
  clearInfo,
  clearLogs,
  getTerminalDataFromSessionStorage,
} from '../../actions/terminal.actions';

import './terminal.sass';

const { TabPane } = Tabs;

const Terminal = ({
  terminal,
  clearLogs,
  clearErrors,
  clearInfo,
  clearTerminal,
  getTerminalDataFromSessionStorage,
}) => {
  const [visibleTerminal, setVisibleTerminal] = useState(false);
  const [anchor, setAnchor] = useState(1);
  const [indicator, setIndicator] = useState(false);
  const roleId = useSelector((store) => store?.auth?.role_id);

  const showTerminal = useCallback(() => {
    setVisibleTerminal(!visibleTerminal);
    setIndicator(false);
  }, [visibleTerminal, setVisibleTerminal]);

  const tabChange = (index) => {
    setAnchor(index);
  };

  const scrollUp = useCallback(() => {
    const top = document.getElementById(`tabTop${anchor}`);
    if (top) top.scrollIntoView({ behavior: 'smooth' });
  }, [anchor]);

  const scrollDown = useCallback(() => {
    const bottom = document.getElementById(`tabBottom${anchor}`);
    if (bottom) bottom.scrollIntoView({ behavior: 'smooth' });
  }, [anchor]);

  useEffect(() => {
    if (terminal.logs.length || terminal.errors.length || terminal.info.length) {
      setIndicator(true);
    }
    if (visibleTerminal) scrollDown();
  }, [terminal.logs, terminal.info, terminal.errors, scrollDown, visibleTerminal, setIndicator]);

  useEffect(() => {
    getTerminalDataFromSessionStorage();
  }, [getTerminalDataFromSessionStorage]);

  useEffect(() => {
    setTimeout(() => scrollDown(), 500);
  }, [anchor, scrollDown]);

  const clear = () => clearTerminal();

  const clearTerminalTab = useCallback(
    (tab) => {
      if (tab === 1) clearLogs();
      if (tab === 2) clearErrors();
      if (tab === 3) clearInfo();
    },
    [clearLogs, clearErrors, clearInfo],
  );

  const tabs = [
    {
      id: 1,
      title: 'Logs',
      data: terminal.logs,
    },
    {
      id: 2,
      title: 'Errors',
      data: terminal.errors,
    },
    {
      id: 3,
      title: 'Info',
      data: terminal.info,
    },
  ];

  return (
    <div className="terminal">
      <div className={`terminal_content ${visibleTerminal && 'visible'}`}>
        <div className={`terminal_content_header ${visibleTerminal && 'visible_content'}`}>
          <Button className="terminal-btn" type="text" onClick={showTerminal}>
            <span className="icon">{'>_'}</span>
            <span className="text">Terminal</span>
            {!visibleTerminal && (
              <>
                {terminal.info.length > 0 && indicator && <Badge className="terminal-btn_badge" count={'i'} />}
                {terminal.logs.length > 0 && indicator && (
                  <Badge
                    className="terminal-btn_badge"
                    count={'l'}
                    style={{ backgroundColor: '#f6c532', color: '#000' }}
                  />
                )}
                {terminal.errors.length > 0 && indicator && (
                  <Badge className="terminal-btn_badge" count={'e'} style={{ backgroundColor: '#ff0000' }} />
                )}
              </>
            )}
          </Button>
          {visibleTerminal && (
            <div>
              <Tabs className="terminal-tabs" defaultActiveKey="1" onChange={tabChange}>
                {tabs.map((tab) => (
                  <TabPane className="terminal-tabs_tab" tab={tab.title} key={tab.id}>
                    {tab.data.length !== 0 && (
                      <Button className="clear-tab" onClick={() => clearTerminalTab(tab.id)} type="text">
                        Clear {tab.title}
                      </Button>
                    )}
                    <div className="terminal-tabs__list">
                      <span id={`tabTop${tab.id}`} />
                      {tab.data.map((str, i) => {
                        const parsedStr = JSON.parse(str);
                        if (roleId === 1 || roleId === parsedStr.UserRole)
                          return <div key={tab.title + i}>{parsedStr?.Info}</div>;
                        return null;
                      })}
                      <span id={`tabBottom${tab.id}`} />
                    </div>
                  </TabPane>
                ))}
              </Tabs>
              <div className="scroll-buttons">
                <Button className="scroll-buttons_btn" onClick={scrollUp} icon={<CaretUpOutlined />} />
                <Button className="scroll-buttons_btn" onClick={scrollDown} icon={<CaretDownOutlined />} />
              </div>
              <Button className="clear-btn" type="text" onClick={clear}>
                Clear All
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Terminal.propTypes = {
  terminal: PropTypes.shape({
    logs: PropTypes.array,
    errors: PropTypes.array,
    info: PropTypes.array,
  }),
};

const mapStateToProps = (state) => ({
  terminal: state.terminal,
});

export default connect(mapStateToProps, {
  clearTerminal,
  clearLogs,
  clearErrors,
  clearInfo,
  getTerminalDataFromSessionStorage,
})(Terminal);
