import { GET_KEYS, ADD_KEY, DELETE_KEY, UPDATE_KEY, LOGOUT } from '../../constants/actionTypes';

const initialState = {};

export default function keys(state = initialState, action = {}) {
  switch (action.type) {
    case GET_KEYS:
      let list = action.payload.Data;
      let updated = [];
      for (let i = 0; i < list.length; i++) {
        updated.push({
          ...list[i],
          key: (list[i].ID - 1).toString(),
          password: '',
        });
      }
      return {
        ...action.payload,
        Data: updated,
      };
    case ADD_KEY: {
      return {
        ...state,
        Data: [...state.Data, action.payload],
      };
    }
    case UPDATE_KEY: {
      const newData = [...state.Data];
      const index = newData.findIndex((key) => key.ID === action.payload.ID);
      if (index > -1) newData[index] = action.payload;

      return {
        ...state,
        Data: newData,
      };
    }
    case DELETE_KEY: {
      const newData = [...state.Data];
      const index = newData.findIndex((key) => key.ID === action.payload.ID);
      if (index > -1) newData.splice(index, 1);

      return {
        ...state,
        Data: newData,
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
