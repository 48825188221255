export const routes = {
  HOME: '/',
  DEVICE: '/:id',
  DEFAULT: '/0000000000000',
  KEYS: '/keys',
  TEMPLATES: '/templates',
  SOFTWARE: '/software',
  SETTINGS: '/settings',
  ERROR: '/error',
};

export const routesName = {
  HOME: 'Devices',
  DEVICE: 'Device',
  DEFAULT: 'Default',
  KEYS: 'Keys',
  TEMPLATES: 'Templates',
  SOFTWARE: 'Software',
  SETTINGS: 'Settings',
  ERROR: 'Error',
};
