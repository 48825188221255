import { GET_DEFAULT_CLIENT, LOGOUT, UPDATE_DEFAULT_CLIENT } from '../../constants/actionTypes';

const initialState = {};

export default function defaultClient(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DEFAULT_CLIENT:
      return {
        ...action.payload,
      };
    case UPDATE_DEFAULT_CLIENT:
      return {
        ...action.payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
