import {
  CLEAR_ERRORS,
  CLEAR_INFO,
  CLEAR_LOGS,
  CLEAR_TERMINAL,
  GET_TERMINAL_DATA_FROM_SESSION_STORAGE,
  LOGOUT,
  SET_ERRORS,
  SET_INFO,
  SET_LOGS,
} from '../../constants/actionTypes';

const initialState = {
  logs: [],
  errors: [],
  info: [],
};

const getSessionTerminalData = (type, payload) => {
  let sessionData = JSON.parse(sessionStorage.getItem(`session${type}`));
  if (sessionData && sessionData.length) {
    if (sessionData.length > 1000) sessionData.pop();
    sessionData.push(payload);
    sessionStorage.setItem(`session${type}`, JSON.stringify(sessionData));
  } else {
    sessionData = [payload];
    sessionStorage.setItem(`session${type}`, JSON.stringify([payload]));
  }

  return sessionData;
};

export default function terminal(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TERMINAL_DATA_FROM_SESSION_STORAGE: {
      const sessionLogs = JSON.parse(sessionStorage.getItem('sessionLogs'));
      const sessionErrors = JSON.parse(sessionStorage.getItem('sessionErrors'));
      const sessionInfo = JSON.parse(sessionStorage.getItem('sessionInfo'));

      return {
        ...state,
        logs: sessionLogs ? sessionLogs : [],
        errors: sessionErrors ? sessionErrors : [],
        info: sessionInfo ? sessionInfo : [],
      };
    }

    case SET_LOGS: {
      const sessionLogs = getSessionTerminalData('Logs', action.payload);

      return {
        ...state,
        logs: sessionLogs,
      };
    }
    case CLEAR_LOGS: {
      sessionStorage.removeItem('sessionLogs');
      return {
        ...state,
        logs: [],
      };
    }
    case SET_ERRORS: {
      const sessionErrors = getSessionTerminalData('Errors', action.payload);
      return {
        ...state,
        errors: sessionErrors,
      };
    }
    case CLEAR_ERRORS: {
      sessionStorage.removeItem('sessionErrors');
      return {
        ...state,
        errors: [],
      };
    }
    case SET_INFO: {
      const sessionInfo = getSessionTerminalData('Info', action.payload);
      return {
        ...state,
        info: sessionInfo,
      };
    }
    case CLEAR_INFO: {
      sessionStorage.removeItem('sessionInfo');
      return {
        ...state,
        info: [],
      };
    }
    case CLEAR_TERMINAL:
      sessionStorage.removeItem('sessionInfo');
      sessionStorage.removeItem('sessionErrors');
      sessionStorage.removeItem('sessionErrors');
      return {
        ...initialState,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
