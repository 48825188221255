import React, { useCallback, useEffect, useState } from 'react';
import * as moment from 'moment';

import { Button, Table, Popconfirm, Space, Form, Input } from 'antd';
import { EditFilled, DeleteFilled } from '@ant-design/icons';

import EditableCell from './EditableCell';

const UsersTable = ({ users, updateUser, deleteUser }) => {
  const [originData, setOriginData] = useState([]);

  useEffect(() => {
    setOriginData(users?.Data);
  }, [users]);

  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editableUser, setEditableUser] = useState({});

  useEffect(() => {
    if (originData?.length !== 0) {
      setData(originData);
    }
  }, [originData]);

  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.key === editingKey;

  const changeHandler = useCallback(
    (record, type) => (e) => {
      if (type === 'status') {
        setEditableUser({
          ...record,
          status: e,
        });
      } else {
        const { name, value } = e.target;
        setEditableUser({
          ...record,
          [name]: value,
        });
      }
    },
    [setEditableUser],
  );
  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      status: '',
      role: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const removeUser = (record) => {
    deleteUser(record.ID);
  };

  const save = (record) => {
    updateUser(record.ID, editableUser);
    setEditingKey('');
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'username',
      width: '25%',
      editable: true,
    },
    {
      title: 'Password',
      dataIndex: 'password',
      width: '15%',
      editable: true,
      render: (pwd) => (
        <div>
          <Input value={pwd} type="password" bordered={false} style={{ paddingLeft: 0 }} />
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      editable: true,
      render: (status) => <div>{status === '1' ? 'online' : 'offline'}</div>,
    },
    {
      title: 'Last update',
      dataIndex: 'UpdatedAt',
      editable: true,
      render: (time) => <div>{moment(time).format('DD-MM-YYYY HH:mm')}</div>,
    },
    // {
    //   title: 'Role',
    //   dataIndex: 'role',
    //   editable: true,
    //   render: (role) => <Tag color={role === 'admin' ? 'green' : 'blue'}>{role.toUpperCase()}</Tag>,
    // },
    {
      title: 'Operations',
      dataIndex: 'operations',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space size="small">
            <Button type="text" onClick={() => save(record)}>
              Save
            </Button>
            <Popconfirm title={'Sure cancel editing user ' + record.username + '?'} onConfirm={cancel}>
              <Button type="text">Cancel</Button>
            </Popconfirm>
          </Space>
        ) : (
          <Space size="small">
            <Button type="text" disabled={editingKey !== ''} onClick={() => edit(record)} icon={<EditFilled />} />
            <Popconfirm title={'Sure delete user ' + record.username + '?'} onConfirm={() => removeUser(record)}>
              <Button type="text" disabled={editingKey !== ''} icon={<DeleteFilled />} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        onChange: changeHandler(record, col.dataIndex),
        inputType: col.dataIndex === 'status' ? 'select' : 'input',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <div className="users-table">
      <Form className="users-table_form" form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          className="users-table_form_table"
          size="small"
          dataSource={data}
          columns={mergedColumns}
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
    </div>
  );
};

export default UsersTable;
