import { GET_USER, LOGIN, LOGOUT } from '../../constants/actionTypes';

export default function auth(state = {}, action) {
  switch (action.type) {
    case LOGIN:
      return state;
    case GET_USER:
      return {
        ...action.payload,
      };
    case LOGOUT:
      return {};
    default:
      return state;
  }
}
