import { GET_SOFTWARE, IMPORT_SOFTWARE, DELETE_SOFTWARE, SAVE_AS_TEMPLATE } from '../../constants/actionTypes';

const initialState = {};

export default function software(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SOFTWARE:
      return {
        ...action.payload,
      };
    case DELETE_SOFTWARE: {
      const newSoftware = [...state.Data];
      const index = newSoftware.findIndex((template) => template.ID === action.payload);
      newSoftware.splice(index, 1);

      return {
        ...state,
        Data: newSoftware,
      };
    }
    case SAVE_AS_TEMPLATE: {
      const newTemplates = [...state.Data];
      if (action.payload.Data) newTemplates.push(action.payload.Data);

      return {
        ...state,
        Data: newTemplates,
      };
    }
    case IMPORT_SOFTWARE: {
      const newSoftware = [...state.Data];
      if (action.payload.Data) newSoftware.push(action.payload.Data);

      return {
        ...state,
        Data: newSoftware,
      };
    }
    default:
      return state;
  }
}
