import Axios from 'axios';
import { notificationError } from './default';
import store from '../store';
import history from './history';
import { logout } from '../actions/auth.actions';

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN,
  timeout: 5000,
  responseType: 'json',
});

axios.interceptors.request.use(
  (config) => {
    config.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    // Do something before request is sent
    config.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error?.response?.status;
    if (error?.response === undefined) {
      notificationError(null, error?.message);
      history.push('/error');
    }
    if (status === 500) {
      history.push('/error');
    }
    if (status === 401) {
      store.dispatch(logout());
      history.push('/auth');
    }
    if (error?.response?.data?.Message) {
      notificationError(`Error ${error?.response?.status}`, error?.response?.data?.Message);
    }
    return Promise.reject(error);
  },
);
