import React, { memo, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Modal, Card, Input, Button, Popconfirm } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import './keyModal.sass';

const ClientKeyModal = ({ visible, setVisible, clientKey, addKey, updateKey, deleteKey }) => {
  const { TextArea } = Input;
  const [publicKey, setPublicKey] = useState('');
  const location = useLocation();
  const hideModal = () => {
    setVisible(false);
    setPublicKey('');
  };
  const currentDevice = location.pathname.substr(1);

  const changeHandler = useCallback(
    (e) => {
      setPublicKey(e.target.value);
    },
    [setPublicKey],
  );

  const addClientKey = useCallback(() => {
    addKey(currentDevice, { DeviceID: currentDevice, Key: publicKey, Status: 1 });
    setPublicKey('');
  }, [addKey, currentDevice, publicKey]);

  const updateClientKey = useCallback(() => {
    updateKey(currentDevice, { ...clientKey, Key: publicKey });
    setPublicKey('');
  }, [updateKey, currentDevice, publicKey, clientKey]);

  const deleteClientKey = useCallback(() => {
    deleteKey(currentDevice);
  }, [currentDevice, deleteKey]);

  return (
    <Modal
      className="client-modal"
      visible={visible}
      onOk={hideModal}
      centered={true}
      onCancel={hideModal}
      okText="Done"
    >
      <Card className="client-modal_card" bordered={false} title="CLIENT KEY">
        {clientKey.Key && (
          <div className="section">
            <h4 className="section_title">KEY: </h4>
            <span className="section_text">{clientKey.Key}</span>
            <Popconfirm title="Are you sure to delete this key ?" onConfirm={deleteClientKey}>
              <Button className="section_btn" type="text" icon={<CloseCircleOutlined />} />
            </Popconfirm>
          </div>
        )}
        {clientKey.Key ? (
          <div className="section">
            <TextArea rows={4} onChange={changeHandler} placeholder="Update key" name="Key" value={publicKey} />
            <Button className="section_btn" onClick={updateClientKey}>
              Update
            </Button>
          </div>
        ) : (
          <div className="section">
            <TextArea rows={4} onChange={changeHandler} placeholder="Insert key" name="Key" value={publicKey} />
            <Button className="section_btn" onClick={addClientKey}>
              Add key
            </Button>
          </div>
        )}
      </Card>
    </Modal>
  );
};

export default memo(ClientKeyModal);
