import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import { debounce } from 'lodash';

import { Button, Card, Slider, Switch } from 'antd';

import DeviceIndicators from '../DeviceIndicators/deviceIndicators';
import upTimeConvertor from '../../utils/upTimeConvertor';

import './devieCard.sass';

const DeviceCard = ({
  device: { DeviceID, DeviceName, Uptime, Ip, Power, LastCheck, State, ApdWifi },
  setPower,
  setState,
}) => {
  const [currentStatus, setCurrentStatus] = useState(false);
  const history = useHistory();
  let timerCheck = useRef(null);

  const viewDevice = useCallback(() => history.push(DeviceID), [DeviceID, history]);
  const changeSelect = useCallback(
    debounce((power) => setPower(DeviceID, power), 500),
    [DeviceID, setPower],
  );
  const changeSwitch = useCallback((state) => setState(DeviceID, state), [DeviceID, setState]);

  const checkDeviceStatus = useCallback(() => {
    const currentTime = moment()
      .subtract(1.2, 'minute')
      .format('YYYY-MM-DD HH:mm:ss');

    const lastCheckToLocal = moment
      .utc(LastCheck)
      .local()
      .format('YYYY-MM-DD HH:mm:ss');

    if (moment(lastCheckToLocal).isAfter(currentTime, 'minutes')) {
      setCurrentStatus(true);
    } else setCurrentStatus(false);
  }, [LastCheck]);

  useEffect(() => {
    clearInterval(timerCheck.current);
    checkDeviceStatus();

    timerCheck.current = setInterval(() => checkDeviceStatus(), 60 * 1000);

    return () => {
      clearInterval(timerCheck.current);
    };
  }, [LastCheck, checkDeviceStatus]);

  return (
    <Card className="device">
      {/* set if offline, when was last time online */}
      <DeviceIndicators ApdWifi={ApdWifi} status={currentStatus} LastCheck={LastCheck} />
      <div className="device_row">
        <div className="device_row_name">DeviceID</div>
        <div className="device_row_desc">{DeviceID}</div>
      </div>
      <div className="device_row">
        <div className="device_row_name">DeviceName</div>
        <div className="device_row_desc">{DeviceName || 'No device name'}</div>
      </div>
      <div className="device_row">
        <div className="device_row_name">Uptime</div>
        <div className="device_row_desc">{upTimeConvertor(Uptime)}</div>
      </div>
      <div className="device_row">
        <div className="device_row_name">Ip</div>
        <div className="device_row_desc">{Ip.toString().replace(/;/g, ' ')}</div>
      </div>
      <div className="device_actions">
        <div className="device_actions_more">
          <Button shape="round" onClick={viewDevice}>
            View
          </Button>
        </div>

        {/*DEVICE OPTIONS */}
        <div className="device_actions_aside">
          <div className="device_actions_aside_item">
            <div className="device_actions_item device_actions_item-slider">
              <span className="device_actions_item_title ">Power:</span>
              <Slider
                className="device_actions_item-slider__slider"
                defaultValue={Power}
                min={0}
                max={20}
                step={5}
                dots
                onChange={changeSelect}
              />
            </div>
          </div>
          <div className="device_actions_aside_item">
            <Switch
              checked={State}
              onChange={changeSwitch}
              style={{ width: 50 }}
              checkedChildren="on"
              unCheckedChildren="off"
            />
          </div>
        </div>
      </div>
    </Card>
  );
};
export default memo(DeviceCard);
