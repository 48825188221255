import React, { useCallback, useEffect, useState, useMemo } from 'react';
import moment from 'moment';

import { Button, Table, Space, Form, Popconfirm, Popover, Select } from 'antd';
import { DeleteFilled, DownloadOutlined, ImportOutlined } from '@ant-design/icons';

import './templatesPage.sass';

const Option = Select.Option;

const TemplatesTable = ({ templates, deleteTemplate, exportTemplate, users, devices, setTemplateToClient }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(templates?.Data);
  const [selectedDeviceId, setSelectedDeviceId] = useState(undefined);
  const [applyLoading, setApplyLoading] = useState(false);
  const [templatePopupVisibility, setTemplatePopupVisibility] = useState(undefined);

  useEffect(() => {
    if (templates?.Data) setData(templates.Data);
  }, [templates]);

  const removeTemplate = useCallback((record) => deleteTemplate(record.ID), [deleteTemplate]);

  const exportTemplateAction = useCallback((record) => exportTemplate(record.ID), [exportTemplate]);

  const setTemplateAction = useCallback(
    (templateId) => {
      setApplyLoading(true);
      setTemplateToClient(selectedDeviceId, templateId)
        .then(() => {
          setApplyLoading(false);
          setSelectedDeviceId(undefined);
          setTemplatePopupVisibility(undefined);
        })
        .catch(() => setApplyLoading(false));
    },
    [selectedDeviceId, setTemplateToClient],
  );

  const selectDevice = useCallback((deviceId) => setSelectedDeviceId(deviceId), []);

  const columns = useMemo(() => {
    return [
      {
        title: 'Name',
        dataIndex: 'Name',
        editable: false,
        width: 120,
      },
      {
        title: 'User',
        dataIndex: 'UserID',
        editable: false,
        width: 170,
        render: (id) => {
          const index = users?.Data?.findIndex((user) => user.ID === id);
          if (index && index !== -1) {
            return <div>{users?.Data[index]?.username}</div>;
          }
        },
      },
      {
        title: 'Power',
        dataIndex: 'Power',
        width: 50,
        editable: false,
      },
      {
        title: 'Created At',
        dataIndex: 'CreatedAt',
        editable: false,
        render: (time) => <div>{moment(time).format('DD-MM-YYYY HH:mm')}</div>,
        width: 200,
      },
      {
        title: 'Operations',
        dataIndex: 'operations',
        width: 150,
        render: (_, record) => (
          <Space size="small">
            <Popconfirm title={'Sure delete Template ID: ' + record.ID + '?'} onConfirm={() => removeTemplate(record)}>
              <Button type="text" icon={<DeleteFilled />} />
            </Popconfirm>
            <Button type="text" icon={<DownloadOutlined />} onClick={() => exportTemplateAction(record)} />
            <Popover
              placement="left"
              title="Set Template to Device"
              visible={templatePopupVisibility === record.ID}
              onVisibleChange={(e) =>
                e ? setTemplatePopupVisibility(record.ID) : setTemplatePopupVisibility(undefined)
              }
              content={
                <div>
                  <Select
                    placeholder="Select Device"
                    style={{ width: 300 }}
                    onChange={selectDevice}
                    value={selectedDeviceId}
                  >
                    {devices?.map((device) => (
                      <Option key={device.ID} value={device.DeviceID}>
                        {device.DeviceID} - {device.DeviceName}
                      </Option>
                    ))}
                  </Select>
                  <Button
                    style={{ marginLeft: 10 }}
                    onClick={() => setTemplateAction(record.ID)}
                    loading={applyLoading}
                  >
                    Apply
                  </Button>
                </div>
              }
              trigger="click"
            >
              <Button type="text" icon={<ImportOutlined />} />
            </Popover>
          </Space>
        ),
      },
    ];
  }, [
    applyLoading,
    devices,
    exportTemplateAction,
    removeTemplate,
    selectedDeviceId,
    setTemplateAction,
    templatePopupVisibility,
    users,
    selectDevice,
  ]);

  return (
    <div className="templates-table">
      <Form className="templates-table__form" form={form} component={false}>
        <Table
          className="templates-table__table"
          size="small"
          dataSource={data}
          columns={columns}
          // pagination={{
          //   onChange: cancel,
          // }}
          rowKey={'ID'}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <div className="templates-table__table_expand-wrapper">
                  <pre>{JSON.stringify(record, null, 2)}</pre>
                </div>
              );
            },
          }}
        />
      </Form>
    </div>
  );
};

export default TemplatesTable;
