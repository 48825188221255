import { notification } from 'antd';
// NOTIFICATIONS

export const notificationSuccess = (message, description, duration, placement) => {
  return notification.success({
    message: message || 'Operation success',
    description: description || '',
    duration: duration || 4,
    placement: placement || 'bottomRight',
  });
};

export const notificationError = (message, description, duration, placement) => {
  return notification.error({
    message: message || 'Operation failed',
    description: description || '',
    duration: duration || 4,
    placement: placement || 'bottomRight',
  });
};

export const notificationWarning = (message, description, duration, placement) => {
  return notification.warning({
    message: message || 'Warning!',
    description: description || '',
    duration: duration || 4,
    placement: placement || 'bottomRight',
  });
};

// DEFAULT CLIENT
export const defaultClientName = '0000000000000';
