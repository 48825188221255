import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { useLocation } from 'react-router-dom';

const NavBarItem = ({ goTo, routePath, routeName, IconActive, IconDisabled }) => {
  const location = useLocation();
  const isPathActive = (path) => path === location.pathname;
  return (
    <div
      className={`main-header__link-item${isPathActive(routePath) ? ' main-header__link-item--active' : ''}`}
      onClick={goTo}
    >
      {isPathActive(routePath) ? (
        <IconActive className="main-header__link-item_icon" />
      ) : (
        <IconDisabled className="main-header__link-item_icon" />
      )}
      <span className="main-header__link-item_name">{routeName}</span>
    </div>
  );
};

NavBarItem.propTypes = {
  goTo: PropTypes.func.isRequired,
  routePath: PropTypes.string.isRequired,
  routeName: PropTypes.string.isRequired,
  IconActive: PropTypes.object.isRequired,
  IconDisabled: PropTypes.object.isRequired,
};

export default memo(NavBarItem);
