import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import { connect } from 'react-redux';

import { logout } from '../../actions/auth.actions';

import { getDevices, setPower, setState } from '../../actions/devices.actions';
import { getDefaultClient } from '../../actions/defaultClient.actions';

import DeviceCard from '../../components/DeviceCard/deviceCard';

import { defaultClientName } from '../../utils/default';

import './homePage.sass';

const HomePage = ({ getDevices, getDefaultClient, devices, logout, setPower, setState }) => {
  const history = useHistory();

  useEffect(() => {
    getDevices().then(() => getDefaultClient(defaultClientName));
  }, [getDevices, history, logout, getDefaultClient]);

  const setDevicePower = (device, power) => {
    setPower(device, { Power: power });
  };
  const setDeviceState = (device, power) => {
    setState(device, { State: power });
  };

  return (
    <div className="rwm-home">
      {/*DEVICES LIST */}
      {devices?.Data?.map((device) => (
        <DeviceCard key={device.ID} device={device} setPower={setDevicePower} setState={setDeviceState} />
      ))}
    </div>
  );
};

HomePage.propTypes = {
  devices: PropTypes.shape({
    Message: PropTypes.string,
    Data: PropTypes.array,
  }),
};

const mapStateToProps = (state) => ({
  devices: state.devices,
  status: state.terminal?.status,
});

export default connect(mapStateToProps, { getDevices, logout, setPower, setState, getDefaultClient })(HomePage);
