import React, { useCallback, useState } from 'react';

import { Card, Modal, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const ImportSoftware = ({ modalVisibility, setVisibility, importSoftware }) => {
  const [file, setFile] = useState();

  const uploadFile = useCallback(() => {
    importSoftware(file).then(() => setVisibility(false));
  }, [file, setVisibility, importSoftware]);

  const uploadOnChange = (info) => {
    setFile(info?.file?.originFileObj);
  };

  const cancelImport = () => setVisibility(false);

  return (
    <Modal onCancel={cancelImport} onOk={uploadFile} visible={modalVisibility} centered={true}>
      <Card bordered={false} className="add-card" title="IMPORT SOFTWARE">
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }} className="add-card_section">
          <Upload style={{ marginTop: 10 }} onChange={uploadOnChange}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </div>
      </Card>
    </Modal>
  );
};

export default ImportSoftware;
