import api from '../utils/api';

import { notificationSuccess } from '../utils/default';

import { ADD_KEY, GET_KEY, ERRORS, DELETE_KEY, UPDATE_KEY } from '../constants/actionTypes';
import { getKeys } from './keys.actions';

export const getKey = (id) => (dispatch) => {
  return api.clientKey
    .getKey(id)
    .then((res) => {
      // notificationSuccess(null, res.data.Message);
      dispatch({
        type: GET_KEY,
        payload: res.data.Data,
      });
      dispatch({
        type: ERRORS,
        payload: {},
      });
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      dispatch({
        type: ERRORS,
        payload: err.response,
      });
      return Promise.reject(err);
    });
};

export const addKey = (id, data) => (dispatch) => {
  return api.clientKey
    .addKey(id, data)
    .then((res) => {
      dispatch({ type: ADD_KEY, payload: res?.data?.Data });
      notificationSuccess(null, res.data.Message);
      return api.clientKey.getKey(id).then((res) => {
        dispatch({
          type: GET_KEY,
          payload: res.data.Data,
        });
        dispatch({
          type: ERRORS,
          payload: {},
        });
        return Promise.resolve(res.data);
      });
    })
    .catch((err) => {
      dispatch({
        type: ERRORS,
        payload: err.response.data,
      });
      return Promise.reject(err);
    });
};

export const updateKey = (id, data) => (dispatch) => {
  return api.clientKey
    .updateKey(id, data)
    .then((res) => {
      dispatch({ type: UPDATE_KEY, payload: res?.data?.Data });
      notificationSuccess(null, res.data.Message);
      return api.clientKey.getKey(id).then((res) => {
        dispatch({
          type: GET_KEY,
          payload: res.data.Data,
        });
        dispatch({
          type: ERRORS,
          payload: {},
        });
        return Promise.resolve(res.data);
      });
    })
    .catch((err) => {
      dispatch({
        type: ERRORS,
        payload: err.response.data,
      });
      return Promise.reject(err);
    });
};

export const deleteKey = (id) => (dispatch) => {
  return api.clientKey
    .deleteKey(id)
    .then((res) => {
      dispatch({ type: DELETE_KEY, payload: res?.data?.Data });
      notificationSuccess(null, res.data.Message);
      dispatch(getKeys());
    })
    .catch((err) => {
      dispatch({
        type: ERRORS,
        payload: err.response.data,
      });
      return Promise.reject(err);
    });
};
