import api from '../utils/api';
import { GET_CELL_INFO, SET_CELL_INFO } from '../constants/actionTypes';

export const getCellInfo = (id) => (dispatch) =>
  api.cellInfo
    .get(id)
    .then((res) => {
      dispatch({ type: GET_CELL_INFO, payload: { id: id, data: res.data.Data } });

      return Promise.resolve(res.data.Data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const setCellInfo = (id) => (dispatch) =>
  api.cellInfo
    .set(id)
    .then((res) => {
      dispatch({ type: SET_CELL_INFO, payload: id });

      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
