import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const UnAuthRoute = ({ component: Component, layout: Layout, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !!localStorage.getItem('user') ? (
          <Redirect to="/" />
        ) : (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }
    />
  );
};

export default UnAuthRoute;
